<template>
    <header class="c-header">
        <a
            href="https://adcc2022.iceberg.app/sign_in"
            target="_blank"
            rel='noopener noreferrer'
            class="c-header_title | o-link -hover-secondary"
        >
            <span class="o-anim-text -mask-y">
                <span>ADCC</span>
            </span>
            <span class="o-anim-text -mask-y">
                <span>AWARDS 2022</span>
            </span>
        </a>

        <div class="c-header_inner">
            <ul class="c-header_list">
                <li class="c-header_item">
                    <span class="o-anim-text -mask-y">
                        <span>
                            Annual
                        </span>
                    </span>
                    <span class="o-anim-text -mask-y">
                        <span>
                            Ceremony
                        </span>
                    </span>
                </li>
                <li class="c-header_item">
                    <span class="o-anim-text -mask-y">
                        <span>
                            Thursday 17,
                        </span>
                    </span>
                    <span class="o-anim-text -mask-y">
                        <span>
                            November
                        </span>
                    </span>
                </li>
                <li class="c-header_item">
                    <span class="o-anim-text -mask-y">
                        <span>
                            17:30
                        </span>
                    </span>
                    <span class="o-anim-text -mask-y">
                        <span>
                            EST
                        </span>
                    </span>
                </li>
                <li class="c-header_item">
                    <span class="o-anim-text -mask-y">
                        <span>
                            Koerner Hall
                        </span>
                    </span>
                    <span class="o-anim-text -mask-y">
                        <span>
                            (Toronto)
                        </span>
                    </span>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>

export default {
    name: 'AppHeader',
}

</script>

<style lang="scss">

.c-header {
    --header-delay: .7s;
}

.c-header_inner,
.c-header_title {
    z-index: 100;
    position: absolute;
}

.c-header_title {
    top: var(--grid-gutter);
    display: flex;
    flex-direction: column;
    color: var(--color-primary);

    .o-anim-text {

        html.is-ready & {
            @include transition-text-in;

            &:nth-child(1) {
                --anim-text-delay-in: var(--header-delay);
            }

            &:nth-child(2) {
                --anim-text-delay-in: calc(var(--header-delay) + .1s);
            }
        }
    }

    html.is-mobile & {
        left: 0;
        width: 100%;
        text-align: center;
    }

    html.is-desktop & {
        left: grid-space(2/6);
    }
}

.c-header_inner {
    display: flex;
    align-items: flex-end;
    height: var(--header-sidebar-size);
    transform-origin: 100% 100%;
    transition: color $speed $easing;

    html.is-mobile & {
        right: var(--grid-gutter);
        bottom: vh(-100);
        width: vh(100);
        padding-right: calc(2 * var(--grid-gutter));
        padding-left: calc(2 * var(--grid-gutter));
        color: var(--color-gray);
        transform: rotate(-90deg);
    }

    html.is-desktop & {
        top: 0;
        right: 0;
        width: vh(70);
        padding-right: var(--grid-gutter);
        padding-left: var(--grid-gutter);
        color: var(--color-dark);
        transform: rotate(-90deg) translate(var(--header-sidebar-size), 0);
    }
}

.c-header_list {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.c-header_item {
    display: flex;
    flex-direction: column;
    font-size: rem(15px);
    line-height: 1;

    .o-anim-text {

        &:nth-child(1) {
            --anim-text-delay-in: var(--header-delay);
        }

        &:nth-child(2) {
            --anim-text-delay-in: calc(var(--header-delay) + .1s);
        }

        html.is-ready & {
            @include transition-text-in;
        }
    }
}

</style>
