import { punchlines } from '@/data'

/*
** Content
*/

// State
const state = {
    punchlines,
    DPI: window.devicePixelRatio || 1,
}

// Getters
const getters = {
    getRandomPunchline      : (state, getters)  => state.punchlines[Math.floor(Math.random() * getters.totalPunchlines)],
    totalPunchlines         : state             => state.punchlines.length,
    getShuffledPunchlines   : (state, getters)  => (limit = getters.totalPunchlines) => {

        // Maximum punchline
        limit = Math.min(limit, getters.totalPunchlines)
        const punchlines = [...state.punchlines]
        punchlines.length = limit
        return punchlines.sort(() => 0.5 - Math.random())
    },
}

// Actions
const actions = {
}

// Mutations
const mutations = {
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
