import { createApp } from 'vue'
import { FocusTrap } from 'focus-trap-vue'
import App from '@/App.vue'
import store from '@/store'
import cursor from '@/directives/cursor'
import debounce from '@/utils/debounce'
import '@/assets/styles/main.scss'

// Add resizeEnd event to window
const resizeEndEvent = new CustomEvent('resizeEnd');
window.addEventListener('resize', debounce(() => {
    window.dispatchEvent(resizeEndEvent)
}, 200, false))

// Create app
const app = createApp(App)

// Store
app.use(store)

// Directives
app.directive('cursor', cursor)

// Plugins
app.component('FocusTrap', FocusTrap)

// Mount
app.mount('#app')
