/*
** Cursor states
*/

// State
const state = {
    states: [],
    position: {
        x: 0,
        y: 0
    },
    overlayScale: 0,
}

// Getters
const getters = {
    hasState: (state, getters) => getters.totalStates > 0,
    totalStates: state => state.states.length,
    currentState: (state, getters) => getters.hasState ? state.states[getters.totalStates - 1].modifier : false,
    currentLabel: (state, getters) => getters.hasState ? state.states[getters.totalStates - 1].label : false,
}

// Actions
const actions = {
    addState(store, value) {
        if(store.getters.currentState !== value.modifier || store.getters.currentLabel !== value.label) {
            store.commit('addState', value)
        }
    },
    removeState(store, value) {
        if(store.getters.currentState === value.modifier && store.getters.currentLabel === value.label) {
            store.commit('removeState')
        }
    },
    resetState(store) {
        store.commit('resetState')
    },
    updatePosition(store, value) {
        store.commit('updatePosition', value)
    },
    setOverlayScale(store, value) {
        store.commit('setOverlayScale', value)
    }
}

// Mutations
const mutations = {
    addState(state, value) {
        state.states.push(value)
    },
    removeState(state) {
        state.states.pop()
    },
    resetState(state) {
        state.states = []
    },
    updatePosition(state, value) {
        state.position = value
    },
    setOverlayScale(state, value) {
        state.overlayScale = value
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
