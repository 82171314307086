<template>
    <footer class="c-footer">
        <a
            href="http://awards.theadcc.ca"
            target="_blank"
            rel="noopener noreferrer"
            class="c-footer_link | u-link-hidden"
            v-cursor="`Learn more`"
        >
            Learn more about the Advertising and Design Club of Canada
        </a>
        <!-- <logo class="c-footer_logo" /> -->
        <div class="c-footer_inner">
            <a
                href="https://theadcc.ca/"
                target="_blank"
                rel="noopener noreferrer"
                class="c-footer_label | o-link -hover-primary"
            >
                The Advertising & <br>
                Design Club of Canada
            </a>
            <p class="c-footer_label">
                Connecting the dots <br>
                ©ADCC, all rights reserved <br>
                Toronto, CA
            </p>
            <p class="c-footer_label">
                Site by
                <a
                    href="https://locomotive.ca"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="o-link -hover-primary"
                >
                    Locomotive
                </a>
                and <br>
                <a
                    href="https://baillatstudio.com/en"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="o-link -hover-primary"
                >
                    Baillat Studio
                </a>
            </p>
        </div>
    </footer>
</template>

<script>

// import Logo                                         from '@/templates/objects/Logo'

export default {
    name: 'AppFooter',
    // components: {
    //     Logo
    // },
}

</script>

<style lang="scss">

.c-footer {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: vh(100);
    color: var(--color-dark);
    background-color: var(--color-secondary);
    overflow: hidden;
}

// .c-footer_logo {
//     --logo-width: calc(1/3 * 100%);

//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
// }

.c-footer_link {

    &:focus-visible:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid currentcolor;
    }
}

.c-footer_inner {
    z-index: 2;
    display: grid;
    align-items: flex-end;
    grid-gap: var(--grid-gutter);
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 100%;
    padding: var(--grid-gutter);
}

.c-footer_label {
    font-size: rem(15px);
    font-weight: inherit;
    line-height: 1;
    text-transform: uppercase;

    &:nth-child(2) {
        text-align: center;
    }

    &:nth-child(3) {
        text-align: right;
    }
}

</style>
