<template>
    <section class="c-section-punchlines">
        <rail
            tag="ul"
            ref="rail"
            :vertical="true"
            :paused="false"
            class="c-section-punchlines_list"
            aria-hidden="true"
        >
            <li
                v-for="(punchline, i) in punchlines"
                :key="`section-punchline-${i}`"
                class="c-section-punchlines_item | c-heading -h2"
            >
                {{ punchline }}
            </li>
        </rail>
        <button
            ref="button"
            class="c-section-punchlines_button | u-link-hidden"
            v-cursor="`Press`"
            @mousedown="pressStart"
            @mouseup="pressStop(false)"
        >
            Discover a phrase
        </button>
    </section>
</template>

<script>

import Rail                                     from '@/templates/objects/Rail'

import { gsap, ScrollTrigger }                  from '@/gsap';

export default {
    name: 'SectionPunchlines',
    components: {
        Rail
    },
    data: () => ({
        maxPunchlines: 30,
        pressIntervall: null,
        pressTime: 1500,
        pressProxy: {
            value: 0
        }
    }),
    mounted() {

        // Rail speed
        this.tlPress = gsap.fromTo(this.pressProxy,
            {
                value: 0,
            },
            {
                value: 1,
                duration: this.pressTime/1000,
                ease: 'power2.in',
                paused: true,
                onUpdate: () => {
                    this.$store.dispatch('cursor/setOverlayScale', this.pressProxy.value, {root: true})
                    this.$refs.rail.updateSpeed(50 * this.pressProxy.value + 1)
                }
            })


        // Container pin
        this.st = ScrollTrigger.create({
            trigger: this.$el,
            start: 'top top',
            end: 'bottom+=200% bottom',
            pin: true,
            scrub: 0,
            toggleClass: 'is-visible',
        })
    },
    computed: {
        punchlines() {
            return this.$store.getters['global/getShuffledPunchlines'](this.maxPunchlines)
        }
    },
    methods: {
        openModal() {
            this.$store.dispatch('modal/open')
            this.pressStop(true)
        },
        pressStart() {
            if (!this.pressIntervall) {
                this.pressIntervall = setInterval(this.openModal, this.pressTime);
                this.tlPress.timeScale(1).restart()
                this.$store.dispatch('cursor/addState', { modifier: false, label: 'Hold'}, {root: true})
            }
        },
        pressStop(immediate=false) {
            clearInterval(this.pressIntervall)
            this.pressIntervall = null
            this.$store.dispatch('cursor/removeState', { modifier: false, label: 'Hold'}, {root: true})

            if(immediate) {
                this.tlPress.progress(0).pause()
            } else {
                this.tlPress.timeScale(2).reverse()
            }
        },
    }
}
</script>

<style lang="scss">

.c-section-punchlines {
    width: 100%;
    height: 100vh;
    padding-right: var(--grid-gutter);
    padding-left: var(--grid-gutter);
    background-color: var(--color-dark);
}

.c-section-punchlines_list {
    height: 100%;
    overflow: hidden;
}

.c-section-punchlines_item {
}

.c-section-punchlines_button {

    &:focus-visible:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid var(--color-gray);
    }
}

</style>
