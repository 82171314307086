<template>
    <span
        class="c-cursor"
        :class="className"
    >
        <span
            ref="overlay"
            class="c-cursor_overlay"
        ></span>
        <span class="c-cursor_inner">
            <span
                ref="pop"
                class="c-cursor_pop"
            ></span>
            <span
                v-if="label"
                class="c-cursor_label"
            >
                {{ label }}
            </span>
        </span>
    </span>
</template>

<script>

import { mapGetters, mapState }             from 'vuex'
import { gsap }                             from '@/gsap';

export default {
    name: 'AppCursor',
    data: () => ({
        isActive: false,
        position: {
            x: 0,
            y: 0
        }
    }),
    mounted() {
        this.move(0.5 * window.innerWidth, 0.5 * window.innerHeight, 0)

        const popAnimation = gsap.fromTo(this.$refs.pop,
            {
                opacity: .75,
                scale: 1,
            },
            {
                opacity: 0,
                scale: 1.2,
                duration: .6,
                ease: 'power2.out',
                paused: true
            }
        )

        this.setOverlaySize()

        // // Window events
        // window.addEventListener('resizeEnd', () => this.setOverlaySize())

        // Document events
        document.addEventListener('mousemove', e => {
            this.move(e.clientX, e.clientY)
        })

        // Window events
        window.addEventListener('mousedown', () => {
            this.isActive = true
            popAnimation.restart()
        })

        window.addEventListener('mouseup', () => {
            this.isActive = false
        })
    },
    computed: {
        ...mapGetters({
            hasState: 'cursor/hasState',
            state: 'cursor/currentState',
            label: 'cursor/currentLabel',
        }),
        ...mapState({
            overlayScale: state => state.cursor.overlayScale,
        }),
        className() {
            let className = ''

            if (this.hasState) {
                className += ` -${this.state}`
            }

            if(this.label) {
                className += ` -has-label`
            }

            if (this.isActive) {
                className += ' is-active'
            }

            return className
        },
    },
    methods: {
        move(x, y, duration=0.6) {
            gsap.to(this.position, {
                x,
                y,
                duration,
                ease: 'power3.out',
                onUpdate: () => {
                    gsap.set(this.$el, {
                        x: this.position.x,
                        y: this.position.y,
                    })

                    this.$store.dispatch('cursor/updatePosition', {x: this.position.x, y: this.position.y}, {root: true})
                }
            })
        },
        setOverlaySize() {
            const size = 2 * Math.hypot(window.innerWidth, window.innerHeight)
            gsap.set(this.$refs.overlay, {
                '--cursor-overlay-size': `${size}px`
            })
        },
    },
    watch: {
        overlayScale(scale) {
            gsap.set(this.$refs.overlay, {
                scale
            })
        }
    }
}

</script>

<style lang="scss">

html.is-desktop * {
    cursor: none !important;
}

.c-cursor {
    --cursor-color-text : var(--color-dark);
    --cursor-color-bg   : var(--color-primary);
    --cursor-size       : 140px;

    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: var(--cursor-size);
    height: var(--cursor-size);
    margin-top: calc(-0.5 * var(--cursor-size));
    margin-left: calc(-0.5 * var(--cursor-size));
    pointer-events: none;
    will-change: transform;

    &.-orange {
        --cursor-color-text : var(--color-primary);
        --cursor-color-bg   : var(--color-secondary);
    }

    @media (hover: none) {
        display: none;
        visibility: hidden;
    }

    @media (min-width: $from-huge) {
        --cursor-size: 160px;
    }

    @media (min-width: $from-gigantic) {
        --cursor-size: 180px;
    }
}

.c-cursor_pop,
.c-cursor_inner,
.c-cursor_overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--cursor-color-bg);
    border-radius: 50%;
}

.c-cursor_overlay {
    --cursor-overlay-size: 100vmax;

    top: 50%;
    left: 50%;
    width: var(--cursor-overlay-size);
    height: var(--cursor-overlay-size);
    margin-top: calc(-0.5 * var(--cursor-overlay-size));
    margin-left: calc(-0.5 * var(--cursor-overlay-size));
    transform: scale(0);
    pointer-events: none;
}

.c-cursor_inner {
    color: var(--cursor-color-text);
    transform: scale(.07);
    transition:
        color $speed $easing,
        background-color $speed $easing,
        opacity $speed $easing,
        transform $speed $easing;

    .c-cursor.-has-label & {
        transform: scale(1);
    }

    html:not(.is-ready) & {
        transform: scale(0.035);
    }
}

.c-cursor_pop {
}

.c-cursor_label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1em;
    font-size: rem(15px);
    text-align: center;
    text-transform: uppercase;
    transition: transform .1s;

    .c-cursor.is-active & {
        transform: scale(1.05);
    }
}


/*----------  Animations  ----------*/

@keyframes anim-cursor-active {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1.2);
    }
}



</style>
