import store from '@/store'

const Cursor = {
    created($el, binding) {
        $el.enabled = binding.value !== false
        // $el.isHovering = false

        if($el.enabled) {
            const state = {
                modifier: Object.keys(binding.modifiers)[0] || false,
                label: binding.value || false,
            }

            $el.onEnter = () => {
                store.dispatch('cursor/addState', state, {root: true})
                console.log('onEnter', state.label)
                // $el.isHovering = true
            }

            $el.onLeave = () => {
                store.dispatch('cursor/removeState', state, {root: true})
                console.log('onLeave', state.label)
                // $el.isHovering = false
            }

            $el.addEventListener('mouseenter', $el.onEnter)
            $el.addEventListener('mouseleave', $el.onLeave)
        }
    },
    beforeUnmounted($el) {

        if($el.enabled) {
            $el.removeEventListener('mouseenter', $el.onEnter)
            $el.removeEventListener('mouseleave', $el.onLeave)
        }
    }
}

export default Cursor
