<template>
    <div
        class="o-logo"
        aria-hidden="true"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 521 217"
            class="o-logo_svg"
        >
            <circle cx="62" cy="17.5" r="17"/>
            <circle cx="17" cy="63" r="17"/>
            <circle cx="17" cy="109" r="17"/>
            <circle cx="17" cy="154.5" r="17"/>
            <circle cx="17" cy="200" r="17"/>
            <circle cx="105.5" cy="63" r="17"/>
            <circle cx="105.5" cy="109" r="17"/>
            <circle cx="105.5" cy="154.5" r="17"/>
            <circle cx="105.5" cy="200" r="17"/>
            <circle cx="62" cy="109" r="17"/>
            <circle cx="150.5" cy="63" r="17"/>
            <circle cx="238.5" cy="63" r="17"/>
            <circle cx="150.5" cy="17" r="17"/>
            <circle cx="194.5" cy="17" r="17"/>
            <circle cx="150.5" cy="109" r="17"/>
            <circle cx="238.5" cy="109" r="17"/>
            <circle cx="150.5" cy="154.5" r="17"/>
            <circle cx="238.5" cy="154.5" r="17"/>
            <circle cx="283.5" cy="63" r="17"/>
            <circle cx="327" cy="17.5" r="17"/>
            <circle cx="327" cy="200" r="17"/>
            <circle cx="371" cy="63" r="17"/>
            <circle cx="283.5" cy="109" r="17"/>
            <circle cx="283.5" cy="154.5" r="17"/>
            <circle cx="371" cy="154.5" r="17"/>
            <circle cx="415.5" cy="63" r="17"/>
            <circle cx="460" cy="17" r="17"/>
            <circle cx="459.5" cy="200" r="17"/>
            <circle cx="504" cy="63" r="17"/>
            <circle cx="415.5" cy="109" r="17"/>
            <circle cx="415.5" cy="154.5" r="17"/>
            <circle cx="504" cy="154.5" r="17"/>
            <circle cx="150.5" cy="200" r="17"/>
            <circle cx="194.5" cy="200" r="17"/>
        </svg>
    </div>
</template>

<script>

export default {
    name: 'Logo',
}

</script>

<style lang="scss">

.o-logo {
    width: var(--logo-width, 100%);
    height: 0;
    padding-top: calc(217/521 * var(--logo-width, 100%));

    svg {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        fill: var(--logo-color, var(--color-dark));
    }
}

</style>
