export function lerp(start, end, amt){
    return (1 - amt) * start + amt * end
}

// Clamp number between 2 values
export function clamp(x, min, max) {
    return Math.min(Math.max(x, min), max);
}

// Round number with n decimals
export const round = (x, n=2) => {
  const decimals = 10 ** n
  return Math.round(x * decimals)/decimals
}

// Random numver from range
export const randomFromRange = (min, max, integer=false) => {
    const random = Math.random() * (max - min) + min
    return integer ? Math.floor(random) : random
}
