<template>
    <div class="o-loader">
        <span class="o-loader_dot"></span>
    </div>
</template>

<script>

export default {
    name: 'Loader',
}

</script>

<style lang="scss">

.o-loader {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--color-dark);

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    html.is-mobile & {

        &:after {
            background-color: var(--color-secondary);
        }
    }

    html.is-desktop & {

        &:after {
            background-color: var(--color-gray);
        }
    }

    html:not(.is-loading) & {
        left: 200vw;
        transition: left 0s .4s;

        &:after {
            opacity: 1;
            transition: opacity .4s $easing;
        }
    }
}

.o-loader_dot {
    display: flex;
    width: 10px;
    height: 10px;

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: var(--color-primary);
        border-radius: 50%;
        will-change: transform;
    }

    html:not(.is-ready) & {

        &:before {
            animation: anim-loader-dot-pulse 1s linear alternate infinite;
        }
    }

    html.is-mobile & {}

    html.is-mobile:not(.is-loading) & {
        opacity: 0;
        transform: scale(0);
        transition: opacity .4s ease-in, transform .4s $easing;
    }

    html.is-desktop & {
        z-index: 1;
    }
}

/*----------  Animations  ----------*/

@keyframes anim-loader-dot-pulse {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.5);
    }
}

</style>
