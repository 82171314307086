import { createStore } from 'vuex';

import cursor from '@/store/modules/cursor';
import global from '@/store/modules/global';
import modal from '@/store/modules/modal';

const store = createStore({
    modules: {
        cursor,
        global,
        modal,
    },
    strict: process.env.NODE_ENV !== 'production'
})

export default store
