<template>
    <focus-trap
        v-model:active="isOpen"
        :escapeDeactivates="true"
    >
        <div
            class="c-modal"
            :class="{ 'is-open' : isOpen }"
        >
            <span class="c-modal_bg"></span>
            <h2 class="c-modal_title | c-heading -h3">
                <span class="o-anim-text -mask-y">
                    <span>connecting</span>
                </span>
                <span class="o-anim-text -mask-y">
                    <span>the</span>
                </span>
                <span class="o-anim-text -mask-y">
                    <span>dots</span>
                </span>
            </h2>
            <div class="c-modal_inner">
                <rail
                    v-if="punchline !== null"
                    :speed="6"
                    class="c-modal_text || c-heading -huge"
                >
                    <span>{{ punchline }}</span>
                    <span>{{ punchline }}</span>
                </rail>
            </div>
            <button
                class="c-modal_close | u-link-hidden"
                @click="close"
            >
                Close modal
            </button>
        </div>
    </focus-trap>
</template>

<script>

import Rail                          from '@/templates/objects/Rail'

import { mapGetters }                from 'vuex'

export default {
    name: 'Modal',
    components: {
        Rail
    },
    data: () => ({
        punchline: null
    }),
    mounted() {

        // Bind keydown events
        document.addEventListener('keydown', e => {
            if(e.key === 'Escape' && this.isOpen) {
                this.close()
            }
        })

        // Audio
        if ('speechSynthesis' in window) {
            this.synth = window.speechSynthesis;
            const voices = this.synth.getVoices();
            this.synth.voice = voices[1]
            this.synth.volume = 0.8
            this.synth.rate = 0.1
            this.synth.pitch = 2
            this.synth.lang = 'en'
        } else {
            this.synth = false
        }
    },
    computed: {
        ...mapGetters({
            isOpen: 'modal/isOpen',
        })
    },
    methods: {
        close() {
            this.$store.dispatch('modal/close')
        },
        sayPunchline() {
            if(this.synth) {
                this.synth.cancel();
                const utterThis = new SpeechSynthesisUtterance(`Connecting the dots ${this.punchline}`);

                this.synth.speak(utterThis);
            }
        }
    },
    watch: {
        isOpen(isOpen) {
            if(isOpen) {
                this.punchline = this.$store.getters['global/getRandomPunchline']
                this.sayPunchline()

                this.$store.dispatch('cursor/addState', { modifier: 'orange', label: 'Close'}, {root: true})
            } else {
                this.$store.dispatch('cursor/removeState', { modifier: 'orange', label: 'Close'}, {root: true})
                setTimeout(() => {
                    if(this.synth) {
                        this.synth.cancel();
                    }
                    this.punchline = null
                }, 600);
            }
        },
    }
}
</script>

<style lang="scss">

.c-modal {
    z-index: 150;
    position: fixed;
    top: 0;
    left: 200vw;
    width: 100%;
    height: 100%;
    color: var(--color-dark);
    overflow: hidden;

    &.is-open {
        left: 0;
    }

    &:not(.is-open) {
        pointer-events: none;
        transition: left 0s .4s;
    }
}

.c-modal_bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);

    .c-modal:not(.is-open) & {
        transform: scale(1, 0);
        transform-origin: 50% 100%;
        transition: transform .4s $easing .2s;
    }
}

.c-modal_title {
    position: absolute;
    top: var(--grid-gutter);
    left: var(--grid-gutter);
    display: flex;
    flex-direction: column;

    .o-anim-text {
        --anim-text-duration-out: .4s;

        @include anim-text-out;

        &:nth-child(2) {
            --anim-text-delay-in    : .1s;

            padding-left: 5em;
        }

        &:nth-child(3) {
            --anim-text-delay-in    : .2s;
        }
    }

    .c-modal.is-open & {

        .o-anim-text {
            @include anim-text-in;
        }
    }
}

.c-modal_inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    transform: translate(0, calc(50% + 0.5 * var(--font-size-huge)));
    transition: transform .4s $easing;

    .c-modal.is-open & {
        transform: translate(0);
        transition: transform .6s $easing;
    }
}

.c-modal_text {
    display: flex;
    white-space: nowrap;

    span {
        padding-left: .5em;
    }
}

</style>
