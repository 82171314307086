<template>
    <section class="c-home-mobile">
        <app-header />
        <div class="c-home-mobile_figure">
            <logo class="c-home-mobile_logo" />
        </div>
        <div class="c-home-mobile_info">
            <h1 class="c-home-mobile_title | c-heading -h3">
                Connecting the dots
            </h1>
        </div>
        <span class="c-home-mobile_dot">
            <span>Visit desktop for full experience</span>
        </span>
    </section>
</template>

<script>

import AppHeader                                    from '@/templates/components/AppHeader'
import Logo                                         from '@/templates/objects/Logo'

export default {
    name: 'HomeMobile',
    components: {
        AppHeader,
        Logo
    }
}

</script>

<style lang="scss">

.c-home-mobile_figure {
    display: flex;
    align-items: center;
    justify-content: center;
    height: vh(100);
    padding: 20%;
    background-color: var(--color-secondary);
}

.c-home-mobile_logo {
    opacity: 0;
    transform: scale(1.2);

    html.is-ready & {
        opacity: 1;
        transform: scale(1);
        transition: opacity .5s ease-out, transform 1s $easing;
    }
}

.c-home-mobile_info {
    height: 100vh;
    background-color: var(--color-dark);
}

.c-home-mobile_title {
    --font-size-h3: #{vh(12.5)};

    position: absolute;
    bottom: calc(2 * var(--grid-gutter));
    left: var(--grid-gutter);
    max-width: calc(#{vh(100)} - 4 * var(--grid-gutter));
    transform: rotate(-90deg) translate(0, 1.8em);
    transform-origin: 0 100%;
}

.c-home-mobile_dot {
    position: fixed;
    right: var(--header-sidebar-size);
    bottom: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;
    padding: 20px;
    color: var(--color-dark);
    text-align: center;
    text-transform: uppercase;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: var(--color-primary);
        border-radius: 50%;
        transform: scale(0);
    }

    span {
        display: block;
        margin-top: .5em;
        font-size: 12px;
        opacity: 0;
        transform: translate(0, 1em) scale(.5) rotate(-70deg);
    }

    html.is-ready & {
        animation: anim-home-mobile-dot 9s linear infinite;

        &:before {
            transform: scale(1);
            transition: transform .6s $easing .7s;
        }

        span {
            opacity: 1;
            transform: translate(0) scale(1) rotate(18deg);
            transition: opacity .6s ease-out .7s, transform .6s $easing .7s;
        }
    }
}

@keyframes anim-home-mobile-dot {
    0% {
        transform: translate(0%, 0%) rotate(0);
    }
    33% {
        transform: translate(-10%, 20%) rotate(-20deg);
    }
    66% {
        transform: translate(-30%, -10%) rotate(-10deg);
    }
    100% {
        transform: translate(0%, 0%) rotate(0);
    }
}

</style>
