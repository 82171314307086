<template>
    <section
        class="c-section-poster"
        :class="{ 'has-overlay' : hasDarkOverlay }"
    >
        <app-header />
        <div
            class="c-section-poster_bg -rails"
            aria-hidden="true"
        >
            <rail
                v-for="i in 4"
                :key="`poster-rail-${i}`"
                :ref="`rail${i}`"
                :vertical="true"
                :speed="i % 2 === 1 ? 0.5 : 0.3"
                :paused="hasDarkOverlay"
                class="c-section-poster_rail"
                :class="i % 2 === 0 ? '-date' : '-adcc'"
            >
                <template v-if="i % 2 === 0">
                    <span
                        v-for="j in 4"
                        :key="`poster-rail-${i}-date-${j}`"
                    >
                        <span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            2
                        </span>
                        <span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            0
                        </span>
                        <span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            2
                        </span>
                        <span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            2
                        </span>
                        .
                    </span>
                </template>
                <template v-else>
                    <span
                        v-for="j in 4"
                        :key="`poster-rail-${i}-adcc-${j}`"
                    >
                        <span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            A
                        </span>
                        <span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            D
                        </span>
                        <span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            C
                        </span>
                        <span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            <span class="o-dot"></span>
                            C
                        </span>
                    </span>
                </template>
            </rail>
        </div>
        <h1
            ref="title"
            class="c-section-poster_title | c-heading -h1"
        >
            <span>
                <span class="o-anim-text -mask-y">
                    <span>2022</span>
                </span>
            </span>
            <span>
                <span class="o-anim-text -mask-y">
                    <span>ADCC</span>
                </span>
            </span>
            <span>
                <span class="o-anim-text -mask-y">
                    <span>AWARDS</span>
                </span>
            </span>
        </h1>
        <div
            class="c-section-poster_inner"
            aria-hidden="true"
        >
            <div class="c-section-poster_content">
                <ul
                    v-for="(list, i) in punchlinesList"
                    :key="`poster-punchline-list-${i}`"
                    class="c-section-poster_list"
                    :ref="`col${i+1}`"
                >
                    <li
                        v-for="(punchline, j) in list"
                        :key="`poster-punchline-line-${i}-${j}`"
                        class="c-section-poster_item | o-anim-text -flag"
                    >
                        <span>
                            {{ punchline }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div
            ref="bg"
            class="c-section-poster_bg -dark"
        >
            <h2 class="c-section-poster_sub -place | c-heading -h3">
                <span>Koerner</span>
                <span>Hall</span>
                <span>Toronto</span>
            </h2>
            <h3 class="c-section-poster_sub -date | c-heading -h3">
                <span>
                    Thursday
                    <span>17</span>
                </span>
                <span>November</span>
            </h3>
        </div>
        <floating-dots :isVisible="animateFloatingDots" />
    </section>
</template>

<script>

import Rail                                     from '@/templates/objects/Rail'
import AppHeader                                from '@/templates/components/AppHeader'
import FloatingDots                             from '@/templates/components/FloatingDots'

// import { mapState }                             from 'vuex'
import { gsap }                                 from '@/gsap';

export default {
    name: 'SectionPoster',
    components: {
        AppHeader,
        FloatingDots,
        Rail,
    },
    data: () => ({
        maxPunchlines: 72,
        hasDarkOverlay: false,
        animateFloatingDots: false,
    }),
    mounted() {
        this.setTimeline()

        // // Window events
        // window.addEventListener('resizeEnd', () => {
        //     this.tl.kill()
        //     gsap.set([this.$refs.title, this.$refs.col1, this.$refs.col2, this.$refs.bg], { clearProps: 'all' })

        //     this.$nextTick(() => {
        //         this.setTimeline()
        //     })
        // })
    },
    computed: {
        punchlines() {
            return this.$store.getters['global/getShuffledPunchlines'](this.maxPunchlines)
        },
        punchlinesList() {
            const punchlines = [...this.punchlines]
            const middleIndex = Math.ceil(this.punchlines.length / 2)

            return [
                punchlines.splice(0, middleIndex),
                punchlines.splice(-middleIndex)
            ]
        }
    },
    methods: {
        setTimeline() {
            this.tl = gsap.timeline({
                scrollTrigger: {
                    trigger: this.$el,
                    start: 'top top',
                    end: 'bottom+=300% bottom',
                    pin: true,
                    scrub: 0,
                    onToggle: ({isActive}) => this.animateFloatingDots = isActive
                },
                defaults: {
                    duration: 2,
                    ease: 'power2.in'
                }
            })

            this.tl
                .to(this.$refs.title, {
                    yPercent: -20,
                    onStart: () => {
                        this.hasDarkOverlay = false
                    }
                })
                .to(this.$refs.col1, {
                    yPercent: -30
                }, '-=100%')
                .to(this.$refs.col2, {
                    yPercent: -25
                }, '-=100%')
                .from(this.$refs.bg, {
                    yPercent: 100,
                }, '-=100%')
                .add(() => {
                    this.hasDarkOverlay = this.tl.scrollTrigger.direction > 0
                })
                .set({}, {}, '+=1')
            }
    }
}

</script>

<style lang="scss">

.c-section-poster {
    --poster-padding: var(--grid-gutter);

    width: 100%;
    height: vh(100);
    padding-right: var(--grid-gutter);
    padding-left: var(--grid-gutter);
    color: var(--color-dark);
    background-color: var(--color-gray);
    overflow: hidden;

    &.has-overlay {

        .c-header_inner {
            user-select: none;
        }
    }
}

.c-section-poster_inner {
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.c-section-poster_title {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding-bottom: var(--poster-padding);

    html.is-ready & .o-anim-text {
        @include transition-text-in;
    }

    > span {
        display: block;

        &:nth-child(1) {
            margin-left: -.1em;
        }

        &:nth-child(2) {
            text-align: center;

            .o-anim-text span {
                padding-right: .1em;
                padding-left: .1em;
            }
        }

        &:nth-child(3) {
            margin-right: -.1em;
            text-align: right;
        }

        @for $i from 1 through 3 {

            &:nth-child(#{$i}) span {
                --anim-text-delay-in: calc(.7s + .1s * #{$i - 1});
            }
        }
    }
}

.c-section-poster_content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: var(--grid-gutter);
    height: 100%;
}

.c-section-poster_list {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: var(--grid-gutter);
    padding-bottom: var(--grid-gutter);
}

.c-section-poster_item {
    font-size: rem(12px);

    @for $i from 1 through 36 {
        &:nth-child(#{$i}) {
            --anim-text-delay-in: #{$i/(2 * 36)}s;
        }
    }

    html.is-ready & {
        @include transition-text-in;
    }

    // Spacing
    .c-section-poster_list:nth-child(1) & {

        &:nth-child(18) {
            margin-bottom: auto;
        }

        &:nth-child(19),
        &:nth-child(27) {
            margin-top: auto;
        }
    }

    .c-section-poster_list:nth-child(2) & {

        &:nth-child(12),
        &:nth-child(29) {
            margin-top: auto;
        }

        &:last-child {
            margin-bottom: auto;
        }
    }

    @media (max-height: 700px) {

        &:nth-child(n+27) {
            display: none;
            visibility: hidden;
        }

        .c-section-poster_list:nth-child(2) & {

            &:nth-child(26) {
                margin-bottom: auto;
            }
        }
    }
}

.c-section-poster_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-gray);

    &.-dark {
        z-index: 2;
        display: flex;
        padding: var(--poster-padding);
        color: var(--color-gray);
        background-color: var(--color-dark);
    }

    &.-rails {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        padding-right: var(--poster-padding);
        padding-left: var(--poster-padding);
        color: var(--color-gray-light);
        overflow: hidden;
        user-select: none;
        pointer-events: none;
    }
}

.c-section-poster_rail {
    font-family: ff("secondary");
    font-size: 23vw;
    letter-spacing: -.04em;
    line-height: .9;
    pointer-events: none;
    opacity: 0;
    transform: translate(0, 50vw);

    html.is-ready & {
        opacity: 1;
        transform: translate(0);

        @for $i from 1 through 4 {

            &:nth-child(#{$i}) {
                transition:
                    opacity #{1 + $i/8}s $easing #{$i/8}s,
                    transform #{1 + $i/8}s $easing #{$i/8}s;
            }
        }
    }

    > span {
        left: -.06em;
        display: flex;
        white-space: nowrap;
        writing-mode: vertical-rl;
        text-orientation: mixed;

        span {
            display: inline-block;
        }
    }

    .o-dot {
        z-index: 1;
        position: absolute;
        display: block;
        width: .18em;
        height: .18em;
        background-color: currentColor;
        border-radius: 50%;
    }

    &.-adcc {
        letter-spacing: .025em;

        > span {
            transform: rotate(180deg);
        }

        > span > span {

            // A
            &:nth-child(1) {

                .o-dot {

                    &:nth-child(1) {
                        top: 20%;
                        right: 40%;
                    }

                    &:nth-child(2) {
                        top: 55%;
                        right: 22%;
                    }

                    &:nth-child(3) {
                        top: 55%;
                        right: 65%;
                    }

                    &:nth-child(4) {
                        top: 80%;
                        right: 10%;
                    }
                }
            }

            // D
            &:nth-child(2) .o-dot {

                &:nth-child(1) {
                    top: 13%;
                    right: 10%;
                }

                &:nth-child(2) {
                    top: 13%;
                    left: 12%;
                }

                &:nth-child(3) {
                    top: 50%;
                    right: 38%;
                }
            }

            // C
            &:nth-child(3) {
                margin-top: -.05em;

                .o-dot {

                    &:nth-child(1) {
                        top: 37%;
                        right: 10%;
                    }

                    &:nth-child(2) {
                        top: 67%;
                        right: 14%;
                    }
                }
            }

            // C
            &:nth-child(4) {

                .o-dot {

                    &:nth-child(1) {
                        top: -4%;
                        right: 17%;
                    }

                    &:nth-child(2) {
                        top: -4%;
                        right: 40%;
                    }

                    &:nth-child(3) {
                        top: -4%;
                        left: 15%;
                    }

                    &:nth-child(4) {
                        top: 20%;
                        right: 20%;
                    }

                    &:nth-child(5) {
                        top: 60%;
                        right: 38.5%
                    }
                }
            }
        }
    }

    &.-date {

        > span > span {

            // 2
            &:nth-child(1) {

                .o-dot {

                    &:nth-child(1) {
                        top: 20%;
                        right: 45%;
                    }

                    &:nth-child(2) {
                        top: 20%;
                        right: 67%;
                    }

                    &:nth-child(3) {
                        top: 60%;
                        right: 15%;
                    }

                    &:nth-child(4) {
                        top: 60%;
                        right: 67%;
                    }
                }
            }

            // 0
            &:nth-child(2) {
                padding-top: .15em;

                .o-dot {

                    &:nth-child(1) {
                        top: 3%;
                        right: 15%;
                    }

                    &:nth-child(2) {
                        top: 3%;
                        right: 67%;
                    }

                    &:nth-child(3) {
                        top: 30%;
                        right: 15%;
                    }

                    &:nth-child(4) {
                        top: 30%;
                        right: 67%;
                    }

                    &:nth-child(5) {
                        top: 60%;
                        right: 40%;
                    }

                    &:nth-child(6) {
                        top: 60%;
                        right: 67%;
                    }
                }
            }

            // 2
            &:nth-child(3) {

                .o-dot {

                    &:nth-child(1) {
                        top: 20%;
                        right: 45%;
                    }

                    &:nth-child(2) {
                        top: 60%;
                        right: 15%;
                    }

                    &:nth-child(3) {
                        top: 60%;
                        right: 67%;
                    }
                }
            }

            // 2
            &:nth-child(4) {

                .o-dot {

                    &:nth-child(1) {
                        top: 3%;
                        right: 15%;
                    }

                    &:nth-child(2) {
                        top: 3%;
                        right: 67%;
                    }

                    &:nth-child(3) {
                        top: 40%;
                        right: 40%;
                    }
                }
            }
        }
    }
}

.c-section-poster_sub {
    display: flex;
    flex-direction: column;

    &.-place {

        span:nth-child(2) {
            margin-left: 4em;
        }
    }

    &.-date {
        margin-top: auto;
        margin-left: auto;
        text-align: right;

        span {
            margin-left: 1.5em;
        }
    }
}


</style>
