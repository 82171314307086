<template>
    <div class="c-floating-dots">
        <div class="c-floating-dots_inner">
            <canvas
                ref="canvas"
                aria-hidden="true"
            ></canvas>
        </div>
    </div>
</template>

<script>

import { gsap }                                     from '@/gsap';
import { mapState }                                 from 'vuex'

import { randomFromRange }                          from '@/utils/maths'

export default {
    name: 'FloatingDots',
    props: {
        isVisible: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        size: {
            width: 0,
            height: 0
        },
        dots: [],
        cursorPosition: {
            x: 0,
            y: 0
        }
    }),
    created() {
        this.DOT_RADIUS = 5

        this.TOTAL_DOTS = 36

        let item
        for (let i = 0; i < this.TOTAL_DOTS; i++) {
            this.dots.push({
                x: i >= 0.5 * this.TOTAL_DOTS ? randomFromRange(.6, 1) : randomFromRange(.25, .5),
                y: i >= 0.5 * this.TOTAL_DOTS ? randomFromRange(.6, 1) : randomFromRange(.25, .5),
                delta: i >= 0.5 * this.TOTAL_DOTS ? -1 : -1.5
            })
            item = this.dots[i]
            item.radius = 3 * (item.x + item.y)
        }
    },
    mounted() {
        this.$canvas = this.$refs.canvas
        this.context = this.$canvas.getContext('2d')
        this.setSizes()

        // // Window events
        // window.addEventListener('resizeEnd', () => {
        //     this.setSizes()
        // })

        // Dots radius animations
        this.dotsTimelines = []
        this.dots.forEach((dot, i) => {
            this.dotsTimelines.push(
                gsap.timeline(
                    {
                        paused: true,
                        repeat: -1,
                        delay: 1 * i/(2 * this.TOTAL_DOTS) % 1,
                        defaults: {
                            duration: 1.5
                        }
                    })
                    .to(dot, {
                        radius: 1.25 * dot.radius,
                        x: 1.1 * dot.x,
                        y: 1.1 * dot.y,
                        ease: 'power2.in',
                    })
                    .to(dot, {
                        radius: dot.radius,
                        x: dot.x,
                        y: dot.y,
                        ease: 'power2.out',
                    })
                    .set({}, {}, '+=0.5')
            )
        })

    },
    computed: {
        ...mapState({
            cursor: state => state.cursor.position,
            DPI: state => state.global.DPI
        }),
        radius() {
            return 0.85 * Math.min(this.size.width, this.size.height) / this.DPI
        },
        delta() {
            return {
                x: -1 * (this.cursorPosition.x/this.size.width - 0.5),
                y: -1 * (this.cursorPosition.y/this.size.height - 0.5),
            }
        }
    },
    methods: {

        draw() {
            this.context.clearRect(0, 0, this.$canvas.width, this.$canvas.height);

            // Loop through dots
            let dot, x, y, grad

            for(let i = 0; i < this.TOTAL_DOTS; i++) {
                dot = this.dots[i]

                x = Math.cos(2 * Math.PI * (i/(0.5 * this.TOTAL_DOTS)) - Math.PI/2) * (dot.x * this.radius) + this.size.width + dot.delta * (0.1 * this.size.width * this.delta.x)
                y = Math.sin(2 * Math.PI * (i/(0.5 * this.TOTAL_DOTS)) - Math.PI/2) * (dot.y * this.radius) + this.size.height + dot.delta * (0.1 * this.size.height * this.delta.y)

                x = Math.ceil((x - 0.25) * 2)/2
                y = Math.ceil((y - 0.25) * 2)/2

                // Draw line
                // this.context.strokeStyle = '#8C8C8C'
                grad = this.context.createLinearGradient(x, y, this.size.width, this.size.height);
                grad.addColorStop(0, 'rgba(140, 140, 140, 0.2)');
                grad.addColorStop(0.5, 'rgba(140, 140, 140, 1)');
                grad.addColorStop(1, 'rgba(140, 140, 140, 0.2)');
                this.context.strokeStyle = grad
                this.context.beginPath()
                this.context.moveTo(x, y)
                this.context.lineTo(this.size.width, this.size.height)
                this.context.stroke();

                // Draw dot
                this.context.fillStyle = '#DCFE4A'
                this.context.beginPath()
                this.context.arc(x, y, dot.radius, 0, 2 * Math.PI, true)
                this.context.closePath()
                this.context.fill()
            }

            // Center circle
            this.context.fillStyle = '#DCFE4A';
            this.context.beginPath()
            this.context.arc(this.size.width, this.size.height, this.DOT_RADIUS, 0, 2 * Math.PI, true)
            this.context.closePath()
            this.context.fill()
        },

        setSizes() {
            const width = this.$el.offsetWidth * this.DPI
            const height = this.$el.offsetHeight * this.DPI

            this.$canvas.width = width
            this.$canvas.height = height

            this.size = {
                width: 0.5 * width,
                height: 0.5 * height
            }
        },
    },
    watch: {
        isVisible(active) {
            if(active) {
                gsap.ticker.add(this.draw)
                this.dotsTimelines.forEach(tl => tl.play())
            } else {
                gsap.ticker.remove(this.draw)
                this.dotsTimelines.forEach(tl => tl.pause())
            }
        },
        cursor(pos) {
            gsap.to(this.cursorPosition, {
                x: pos.x,
                y: pos.y,
                duration: .8,
                ease: 'sine.out'
            })
        }
    }
}

</script>

<style lang="scss">

.c-floating-dots {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &:after {
        content: "";
        position: absolute;
        top: calc(50% - 10px/2);
        left: calc(50% - 10px/2);
        display: block;
        width: 10px;
        height: 10px;
        background-color: var(--color-primary);
        border-radius: 50%;
    }

    html.is-ready & {

        &:after {
            transform: scale(0);
            transition: transform .2s .8s;
        }
    }
}

.c-floating-dots_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: scale(0);

    html.is-ready & {
        transform: scale(1);
        transition: transform 1s $easing;
    }
}

</style>
