<template>
    <div ref="scrollWrapper">
        <div ref="scrollContent">
            <main>
                <section-poster />
                <section-mask id="mask1" />
                <section-punchlines />
            </main>
            <app-footer id="footer" />
            <section-poster />
            <section-mask id="mask2" />
        </div>
    </div>
    <modal />
    <app-cursor />
</template>

<script>

import AppCursor                                    from '@/templates/components/AppCursor'
import AppFooter                                    from '@/templates/components/AppFooter'
import Modal                                        from '@/templates/components/Modal'
import SectionMask                                  from '@/templates/components/SectionMask'
import SectionPoster                                from '@/templates/components/SectionPoster'
import SectionPunchlines                            from '@/templates/components/SectionPunchlines'

import { ScrollSmoother, ScrollTrigger }            from '@/gsap'

export default {
    name: 'App',
    components: {
        AppCursor,
        AppFooter,
        Modal,
        SectionMask,
        SectionPoster,
        SectionPunchlines,
    },
    mounted() {

        // Smooth scroll
        this.smoothScroll = ScrollSmoother.create({
            content: this.$refs.scrollContent,
            effets: false,
            normalizeScroll: true,  // forces scrolling to be done on the JavaScript thread
            smooth: 1.5,            // how long (in seconds) it takes to "catch up" to the native scroll position
            wrapper: this.$refs.scrollWrapper,
        });

        //
        // Infinite scroll
        //

        let infiniteScrollUpEnabled = false
        let isLooping = false

        // Scroll bottom
        ScrollTrigger.create({
            trigger: '#mask1',
            start: 'top top',
            onLeaveBack: () => {

                if(!isLooping && infiniteScrollUpEnabled) {
                    this.smoothScroll.scrollTo('#mask2', false, 'top top+=1px')

                    isLooping = true
                    setTimeout(() => { isLooping = false }, 10);
                }
            },
        })

        // Scroll top
        ScrollTrigger.create({
            trigger: '#mask2',
            start: 'top top',
            onEnter: () => {

                if(!isLooping) {
                    this.smoothScroll.paused(true)
                    this.smoothScroll.scrollTo('#mask1', false, 'top top')
                    this.smoothScroll.paused(false)

                    isLooping = true
                    setTimeout(() => { isLooping = false }, 10);
                }
            },
        })

        // Scroll infinite detection
        const infiniteST = ScrollTrigger.create({
            trigger: '#footer',
            start: 'bottom bottom',
            onEnter: () => {
                // Enable infinite scroll up once and destroy instance
                infiniteScrollUpEnabled = true
                infiniteST.kill()
            },
        })

        // Tab navigation
        document.addEventListener('keydown', e => {
            if(e.key === 'Tab') {
                e.preventDefault()
                this.smoothScroll.scrollTo(window.scrollY + window.innerHeight, true)
            }
        })

        // Window events
        window.addEventListener('resizeEnd', () => {
            // this.smoothScroll.scrollTo(0, false)
            // this.$nextTick(() => {
            //     ScrollTrigger.refresh(true)
            // })
            location.reload();
        })
    }
}

</script>
