/*
** Modal
*/

// State
const state = {
    visible: false,
}

// Getters
const getters = {
    isOpen: state => state.visible === true
}

// Actions
const actions = {
    open(store) {
        store.commit('setVisibility', true)
    },
    close(store) {
        store.commit('setVisibility', false)
    }
}

// Mutations
const mutations = {
    setVisibility(state, value) {
        state.visible = value
    }
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
