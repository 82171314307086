/**
 *
 * Data content
 *
**/

export const punchlines = [
    `to draw a picture of the crescent moon`,
    `to finish a picture of freshly picked vegetables`,
    `to see more information`,
    `to reveal a picture of the alphabet`,
    `to create drawings, and you will see a surprise at the end`,
    `to see the big picture`,
    `to see with new eyes`,
    `to complete the patterns for kids`,
    `to complete the crown`,
    `to form squares`,
    `to create airiness`,
    `to a better day at the office using LSD`,
    `to form the back to school picture`,
    `to become an impact player`,
    `to draw the number 42`,
    `to share projects and hire people`,
    `to make this Canada day picture `,
    `to create a quality map`,
    `to find the hero's arms and legs`,
    `to reveal the world's best-loved masterpieces`,
    `to complete the different pictures`,
    `to draw animals`,
    `to recreate shapes`,
    `to discover the picture`,
    `to inspire innovation and turn creativity into money`,
    `to find out what kind of car the clown is driving`,
    `to draw the cute owl vector image`,
    `to create perspective`,
    `to make the snowman come to life `,
    `to access new leads and connect with decision-makers`,
    `to create 60 sexy and exciting pic`,
    `to draw posters, art prints, and canvas wall art`,
    `to encourage the kids to learn numbers `,
    `to discover what kinds of pets dotty has`,
    `to make a tessellation`,
    `to strengthen malaria elimination strategies in India`,
    `to mitigate a third wave`,
    `to discover the cause of ADHD`,
    `to find the answer`,
    `to stick everyone together`,
    `to inspire the leader in you`,
    `to draw the cute cat and color it`,
    `to discover a world of enchantment`,
    `to practice multiplication facts for multiples of 3`,
    `to unpack the language`,
    `o your possible relatives`,
    `to help bring the voices of people to the forefront`,
    `to make the elephant picture`,
    `to feed the needy with food from the heart`,
    `to equity and achievement`,
    `to create a path to success`,
    `to form the curve of your sleeve`,
    `to the future of technology`,
    `to measure antimatter`,
    `to make your network work for you`,
    `to reveal the picture of the city`,
    `to draw the cute sheep`,
    `to make the apple picture`,
    `to find out what animal is living in the garden`,
    `to discover cute animals, shapes, and food.`,
    `to draw a game crab vector`,
    `to help preschoolers, toddlers, and kindergartners`,
    `to reveal a handsome Halloween monster`,
    `to show the mighty dinosaur`,
    `to grow your network  `,
    `to find a helpful timing tool`,
    `to bring the animals to life`,
    `to your possible relatives`,
    `to create 60 sexy and exciting pic`,
    `to pick up clues from a non-trivial location`,
    `to the treasure chest activity `,
    `to our problems`,
    `to get what means a lot to you`,
    `to the terrible plight of farm animals`,
    `to reveal the moon`,
    `to find that next creative breakthrough`,
    `to form a polygon`,
    `to draw a game octopus vector`,
    `to reveal animated figures and illusions`,
    `to create a completely customized kit`,
    `to see the bald eagle catching its favorite food`,
    `to discover the hidden pictures`,
    `to stop terror plots`,
    `to get the 'big picture'`,
    `to find the mystery shape`,
    // `to murder`,
    `to build a better and a bold new future`,
    `to understand interdependencies`,
    `to get the job done`,
    `to your heart`,
    `to what America used to be`,
    `to solve problems`,
    `to things we actually know about`,
    `to get your audience from geekiness to intelligent living`,
    `to how you started to make inroads`,
    `to your purpose`,
    `to trace the path of the ecliptic`,
    `to relevant common core standards`,
    `to shed light`,
    `to their day jobs`,
    `to a longer-term future in gt`,
    `to previously unattributed malicious activity`,
    `to cannabis for themselves`,
    `to get a straight line`,
    `to the more significant problem at hand`,
    `to create barriers`,
    `to address the challenges of global health`,
    `to successfully identify, neutralize, and prevent  `,
];

export default {
    punchlines,
}
