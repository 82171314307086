<template>
    <section class="c-section-mask">
        <h2
            ref="title"
            class="c-section-mask_title | c-heading -h1"
            aria-hidden="true"
        >
            Connecting the dots
        </h2>
        <canvas
            ref="canvas"
            class="c-section-mask_canvas"
            aria-hidden="true"
        ></canvas>
        <span
            ref="bg"
            class="c-section-mask_bg"
        ></span>
    </section>
</template>

<script>

import { mapState }                             from 'vuex'
import { gsap }                                 from '@/gsap';

export default {
    name: 'SectionMask',
    data: () => ({
        dotRadius: 0,
        offset: {
            x: 0,
            y: 0
        }
    }),
    created() {

        // Dots
        this.GRID_DOTS = [
            // Line 1
            { x: 1, y: 0 },
            { x: 3, y: 0 },
            { x: 4, y: 0 },
            { x: 7, y: 0 },
            { x: 10, y: 0 },
            // Line 2
            { x: 0, y: 1 },
            { x: 2, y: 1 },
            { x: 3, y: 1 },
            { x: 5, y: 1 },
            { x: 6, y: 1 },
            { x: 8, y: 1 },
            { x: 9, y: 1 },
            { x: 11, y: 1 },
            // Line 3
            { x: 0, y: 2 },
            { x: 1, y: 2 },
            { x: 2, y: 2 },
            { x: 3, y: 2 },
            { x: 5, y: 2 },
            { x: 6, y: 2 },
            { x: 9, y: 2 },
            // Line 4
            { x: 0, y: 3 },
            { x: 2, y: 3 },
            { x: 3, y: 3 },
            { x: 5, y: 3 },
            { x: 6, y: 3 },
            { x: 8, y: 3 },
            { x: 9, y: 3 },
            { x: 11, y: 3 },
            // Line 5
            { x: 0, y: 4 },
            { x: 2, y: 4 },
            { x: 3, y: 4 },
            { x: 4, y: 4 },
            { x: 7, y: 4 },
            { x: 10, y: 4 },
        ]

        this.GRID_SETTINGS = {
            ROWS: 5,
            COLS: 12,
            SCALE: 0.8,
            GUTTER: 0.25
        }
    },
    mounted() {
        this.$canvas = this.$refs.canvas
        this.context = this.$canvas.getContext('2d');

        this.setSizes()
        this.setTimeline()

        // // Window events
        // window.addEventListener('resizeEnd', () => {
        //     gsap.set([this.$canvas, this.$refs.title, this.$refs.bg], { clearProps: 'all' })
        //     this.setSizes()
        //     this.tl.kill()

        //     this.$nextTick(() => {
        //         this.setTimeline()
        //     })
        // })
    },
    computed: {
        ...mapState({
            DPI: state => state.global.DPI
        }),
        dotsPositions() {
            return this.GRID_DOTS.map(dot => ({
                x: dot.x * 2 * this.dotRadius + this.dotRadius + dot.x + this.offset.x,
                y: dot.y * 2 * this.dotRadius + this.dotRadius + dot.y + this.offset.y,
            }))
        }
    },
    methods: {
        draw() {
            this.context.clearRect(0,0, this.$canvas.width, this.$canvas.height);

            // Fill with orange
            this.context.globalCompositeOperation = 'source-over';
            this.context.fillStyle = '#FC6525';
            this.context.fillRect(0, 0, this.$canvas.width, this.$canvas.height);

            // Draw dots
            this.context.globalCompositeOperation = 'destination-out'
            this.dotsPositions.forEach(dot => {
                this.context.fillStyle = 'black'
                this.context.beginPath()
                this.context.arc(dot.x, dot.y, (1 - this.GRID_SETTINGS.GUTTER) * this.dotRadius, 0, 2 * Math.PI, true)
                this.context.closePath()
                this.context.fill()
            })
        },

        setSizes() {
            const width = window.innerWidth * this.DPI
            const height = window.innerHeight * this.DPI

            this.$canvas.width = width
            this.$canvas.height = height

            this.dotRadius = Math.min((this.GRID_SETTINGS.SCALE * width)/this.GRID_SETTINGS.COLS, (this.GRID_SETTINGS.SCALE * height)/this.GRID_SETTINGS.ROWS)/2

            this.offset.x = width/2 - this.GRID_SETTINGS.COLS * this.dotRadius
            this.offset.y = height/2 - this.GRID_SETTINGS.ROWS * this.dotRadius
        },

        setTimeline() {

            this.tl = gsap.timeline({
                scrollTrigger: {
                    trigger: this.$el,
                    start: 'top top',
                    end: 'bottom+=500% bottom',
                    pin: true,
                    scrub: 0,
                },
                defaults: {
                    duration: 1
                }
            })

            this.tl
                .set(this.$canvas, {
                    opacity: 0
                })
                .set(this.$refs.title, {
                    opacity: 1
                })
                .set(this.$refs.bg, {
                    opacity: 1
                })
                .add(() => {
                    gsap.ticker.remove(this.draw)
                })
                .from(this.dotsPositions, {
                    x: '+=' + this.$canvas.width,
                    y: '+=' + this.$canvas.height/4,
                    duration: 4,
                    ease: 'circ.out',
                    stagger: {
                        amount: 1,
                        axis: 'x, y',
                        from: [0, 0]
                    },
                    onStart: () => {
                        gsap.ticker.add(this.draw)
                        gsap.set(this.$canvas, {
                            opacity: 1
                        })
                        gsap.set(this.$refs.bg, {
                            opacity: 0
                        })
                    }
                })
                .to(this.$canvas, {
                    duration: 3,
                    yPercent: -100,
                    ease: 'sine.inOut',
                }, '-=1')
                .add(() => {
                    gsap.ticker.add(this.draw)
                })
                .add(() => {
                    gsap.ticker.remove(this.draw)
                }, '+=1')
        }
    },
}
</script>

<style lang="scss">

.c-section-mask {
    --font-size-h1: 16.4vw;

    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: vh(100);
    background-color: var(--color-dark);
    overflow: hidden;
}

.c-section-mask_title {
    left: -.015em;
    display: block;
    width: 100%;
    line-height: 1.2;
    text-align: center;
    opacity: 0;
}

.c-section-mask_bg,
.c-section-mask_canvas {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.c-section-mask_bg {
    background-color: var(--color-secondary);
    pointer-events: none;
}


</style>
