<template>

    <!-- Grid debugger for dev -->
    <grid-debugger v-if="IS_DEV" />

    <!-- First loader -->
    <loader />

    <!-- Home mobile/desktop -->
    <home-mobile v-if="IS_MOBILE"/>
    <home-desktop v-else/>

</template>

<script>

import GridDebugger                                 from '@/templates/objects/GridDebugger'
import Loader                                       from '@/templates/objects/Loader'
import HomeDesktop                                  from '@/templates/views/HomeDesktop'
import HomeMobile                                   from '@/templates/views/HomeMobile'

const $html = document.documentElement
const IS_DEV = process.env.NODE_ENV === 'development'
const IS_MOBILE =
    /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
    'ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/) ||
    window.matchMedia('only screen and (max-width: 760px)').matches

export default {
    name: 'App',
    components: {
        GridDebugger,
        Loader,
        HomeDesktop,
        HomeMobile,
    },
    data: () => ({
        IS_DEV,
        IS_MOBILE,
    }),
    created() {
        // Set initial VH var for mobile
        if(IS_MOBILE) {
            $html.style.setProperty('--vh', `${window.innerHeight/100}px`)
            $html.classList.add('is-mobile')
        } else {
            $html.classList.add('is-desktop')
        }
    },
    mounted() {

        const duration = IS_DEV ? 2000 : 4000
        let delay = new Date()
        delay = (delay - window.timestamp)
        delay = delay > duration ? 0 : duration - delay

        setTimeout(() => {
            $html.classList.remove('is-loading')

            setTimeout(() => {
                $html.classList.add('is-ready')
            }, 400);
        }, delay);
    }
}

</script>

<style lang="scss">

</style>
